// This method calculates the percent value for a width in reference to a container
// Ex: Container is 1000px wide an a module hat is 650px , the calc give 0.65
@function calc-ratio($target, $container) {
  @return calc(#{$target} / #{$container});
}

// Returns the percentage value of calc_ratio
@function calc-percent($target, $container) {
  @return percentage(calc-ratio($target, $container));
}

// Shorthand alias
@function cp($target, $container) {
  @return calc-percent($target, $container);
}

// Calculates the ratio of a target to container but with max and min bound inputs
@function calc-bound-ratio($target-max, $target-min, $container-max, $container-min) {
  @return calc-ratio($target-max - $target-min, $container-max - $container-min);
}

// brand colors
$blue: #00aeef;
$text-black: #323232;
$orange: #ef4129;
$light-blue: #cceffc;
$light-orange: #f7d7d2;
$light-green: #ccfcd3;
$green: #0ccc5a;
$yellow: #f1c40f;
$gray: #d8d8d8;
$dark-gray: #888;
$light-gray: #eaeaea;
$lighten-gray: #f9f9f9;
$red: #d0021b;
$bright-red: #ef0000;
$white: #fff;

// additional colors
$gray: #ddd;
$gray-blue: #c1e4f1;
$dark-orange: #cb3621;

// links
$link-red: $orange;
$link-red-hover: #cb3621;
$link-blue: $blue;
$link-blue-hover: #0194cb;
$link-black: $text-black;
$link-black-hover: #bababa;
$link-white: $white;
$link-white-hover: #bababa;

// text
$text-color: $text-black;

// misc
$menu-separator-color: #979797;
$alert-info-bg: #ededed;
$alert-error-bg: #f04b34;
$alert-success-bg: $light-green;
$alert-warning-bg: $light-orange;

$padding: 10px;

// fonts
$font-family-base: HelveticaNeueLTPro-Lt, sans-serif;
$font-family-medium: HelveticaNeueLTPro-Md, sans-serif;
$font-family-bold: HelveticaNeueLTPro-Bd, sans-serif;

$font-default-spacing: 0.4px;

// fonts for Helvetica Neue
$font-weight-thin: 250;
$font-weight-ultra-light: 275;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;
$font-weight-heavy: 750;
$font-weight-black: 900;

//font types for components
$font-family-heading: $font-family-bold;
$font-family-top-menu: $font-family-bold;
$font-family-products-menu: $font-family-base;

// sizes
$font-size-xsmall: 10px;
$line-height-xsmall: 1.33em;

$font-size-small: 12px;
$line-height-small: 1.33em;

$font-size-base: 16px;
$line-height-base: 1.375em;

$font-size-large: 18px;
$line-height-large: 1em;

$font-size-medium: 16px;
$line-height-medium: 1em;

// Headings
// H1
$font-size-h1-mobile: 64px;
$line-height-h1-mobile: 64px;
$letter-spacing-h1-mobile: -2.74px;
$font-size-h1-tablet: 80px;
$line-height-h1-tablet: 80px;
$letter-spacing-h1-tablet: -4px;
$font-size-h1-desktop: 140px;
$line-height-h1-desktop: 125px;
//$letter-spacing-h1-desktop: -4px;

// H2
$font-size-h2-desktop: 90px;
$line-height-h2-desktop: 1.1em;
//$letter-spacing-h2-desktop: -3.86px;
$font-size-h2-mobile: 64px;
$line-height-h2-mobile: 1em;
$letter-spacing-h2-mobile: -2.74px;

// H3
$font-size-h3-desktop: 44px;
$line-height-h3-desktop: 48px;
//$letter-spacing-h3-desktop: -0.66px;
$font-size-h3-mobile: 32px;
$line-height-h3-mobile: 38px;
$letter-spacing-h3-mobile: -0.5px;

// H4
$font-size-h4-desktop: 36px;
$line-height-h4-desktop: 38px;
//$letter-spacing-h4-desktop: -0.53px;
$font-size-h4-mobile: 28px;
$line-height-h4-mobile: 34px;
$letter-spacing-h4-mobile: -0.44px;

$font-size-h5: 20px;
$line-height-h5: 20px;
//$letter-spacing-h5: -0.25px;

$font-size-h6: 18px;
$line-height-h6: 18px;

// Transition defaults
$transition-duration: 0.2s;
$transition-timing-function: ease-out;

// forms
$input-border-color: $gray;
$input-border-color-focus: $blue;
$input-border-radius: 4px;
$placeholder-color: #888;

$input-xs-padding: 10px;
$input-sm-padding: 10px;
$input-md-padding: 12px;
$input-lg-padding: 14px;

$input-xs-icon-size: 14px;
$input-sm-icon-size: 16px;
$input-md-icon-size: 18px;
$input-lg-icon-size: 20px;

// z-indexes
$z-index-nav: 100;
$z-index-add-to-cart: 100;
$z-index-tooltip: 90;
$z-index-radio-tab-hover: 80;
$z-index-radio-tab: 70;
$z-index-data-picker-shown: 10;

// modals
$modal-desktop-padding: 20px;
$modal-mobile-padding: 24px;

// Navigation Bar Height:
$nav-bar-banner-height: 48px;
$nav-bar-height: 87px;
$nav-bar-height-mobile: 63px;
$nav-bar-height-with-banner: calc(
  $nav-bar-banner-height + $nav-bar-height
); // 87 + 48 - TODO: separate and make dynamic
$nav-bar-height-mobile-with-banner: calc($nav-bar-banner-height + $nav-bar-height-mobile); // 63 + 48

// Dynamic font sizing values (unitless)
$min-viewport-width-value: 300;
$grid-xsmall-value: 320;
$grid-small-value: 530;
$grid-medium-value: 768;
$grid-large-value: 1024;
$grid-xlarge-value: 1280;
$grid-2xlarge-value: 1440;
$grid-3xlarge-value: 1536;
$grid-4xlarge-value: 1920;

// Breakpoints (see: `theme.screens` in `tailwind.config.js`)
$min-viewport-width: 300px;
$grid-xsmall: 320px;
$grid-small: 530px;
$grid-medium: 768px;
$grid-large: 1024px;
$grid-xlarge: 1280px;
$grid-2xlarge: 1440px;
$grid-3xlarge: 1536px;
$grid-4xlarge: 1920px;

// Gutter
$gutter-width: 24px;
$gutter-compensation: calc($gutter-width / 2 * -1);
$half-gutter-width: calc($gutter-width / 2);
$gutter-width-mobile: 8px;
$gutter-compensation-mobile: calc($gutter-width-mobile / 2 * -1);
$half-gutter-width-mobile: calc($gutter-width-mobile / 2);

$page-margin-mobile: 8px;

@mixin narrower-than-xsmall {
  @media (max-width: $grid-xsmall) {
    @content;
  }
}

@mixin narrower-than-small {
  @media (max-width: $grid-small) {
    @content;
  }
}

@mixin narrower-than-medium {
  @media (max-width: $grid-medium - 1) {
    @content;
  }
}

@mixin narrower-than-large {
  @media (max-width: $grid-large - 1) {
    @content;
  }
}

@mixin narrower-than-xlarge {
  @media (max-width: $grid-xlarge - 1) {
    @content;
  }
}

@mixin narrower-than-2xlarge {
  @media (max-width: $grid-2xlarge - 1) {
    @content;
  }
}

@mixin narrower-than-3xlarge {
  @media (max-width: $grid-3xlarge - 1) {
    @content;
  }
}

@mixin narrower-than-4xlarge {
  @media (max-width: $grid-4xlarge - 1) {
    @content;
  }
}

@mixin wider-than-xsmall {
  @media (min-width: $grid-xsmall) {
    @content;
  }
}

@mixin wider-than-small {
  @media (min-width: $grid-small) {
    @content;
  }
}

@mixin wider-than-medium {
  @media (min-width: $grid-medium) {
    @content;
  }
}

@mixin wider-than-large {
  @media (min-width: $grid-large) {
    @content;
  }
}

@mixin wider-than-xlarge {
  @media (min-width: $grid-xlarge) {
    @content;
  }
}

@mixin wider-than-2xlarge {
  @media (min-width: $grid-2xlarge) {
    @content;
  }
}

@mixin wider-than-3xlarge {
  @media (min-width: $grid-3xlarge) {
    @content;
  }
}

@mixin wider-than-4xlarge {
  @media (min-width: $grid-4xlarge) {
    @content;
  }
}

$yv-tabbed-content-height: 143px;

$yv-search-tab-height: 48px;

$yv-header-height: 60px;

$yv-tablist-height: 35px;
$yv-tablist-margin-bottom: 0;

$yv-panel-offset: $yv-header-height + $yv-tablist-height + $yv-tablist-margin-bottom;

$yv-summary-footer-height: 55px;

$yv-sidebar-summary-height: 242px;
$yv-sidebar-comment-title-height: 22px;
$yv-sidebar-comments-offset: $yv-sidebar-summary-height + $yv-sidebar-comment-title-height +
  $yv-tablist-height;

@for $i from 1 through 12 {
  .Col--sm-#{$i} {
    @apply block;
    flex-basis: #{calc(100% / 12 * $i)};
    max-width: #{calc(100% / 12 * $i)};
  }
}

@for $i from 1 through 12 {
  @media (min-width: $grid-medium) {
    .Col--md-#{$i} {
      @apply block;
      flex-basis: #{calc(100% / 12 * $i)};
      max-width: #{calc(100% / 12 * $i)};
    }
  }
}

@for $i from 1 through 12 {
  @media (min-width: $grid-large) {
    .Col--lg-#{$i} {
      @apply block;
      flex-basis: #{calc(100% / 12 * $i)};
      max-width: #{calc(100% / 12 * $i)};
    }
  }
}

@for $i from 1 through 12 {
  @media (min-width: $grid-xlarge) {
    .Col--xlg-#{$i} {
      @apply block;
      flex-basis: #{calc(100% / 12 * $i)};
      max-width: #{calc(100% / 12 * $i)};
    }
  }
}

@for $i from 1 through 12 {
  @media (min-width: $grid-2xlarge) {
    .Col--2xlg-#{$i} {
      @apply block;
      flex-basis: #{calc(100% / 12 * $i)};
      max-width: #{calc(100% / 12 * $i)};
    }
  }
}

@for $i from 1 through 12 {
  @media (min-width: $grid-3xlarge) {
    .Col--3xlg-#{$i} {
      @apply block;
      flex-basis: #{calc(100% / 12 * $i)};
      max-width: #{calc(100% / 12 * $i)};
    }
  }
}

@for $i from 1 through 12 {
  @media (min-width: $grid-4xlarge) {
    .Col--4xlg-#{$i} {
      @apply block;
      flex-basis: #{calc(100% / 12 * $i)};
      max-width: #{calc(100% / 12 * $i)};
    }
  }
}
